

const TabCard = ({
  tabData,
  selectTab,
  currentTab,
}) => {
  return (
    <div className="tw-grid tw-grid-cols-1 tw-bottom-0 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4 tw-my-4">
      {tabData.map((request, i) => (
        <div
          key={i}
          className={`${
            request.id === currentTab ? "tw-bg-slate-100" : "tw-bg-white"
          } tw-py-4 tw-cursor-pointer tw-shadow-sm -tw-top tw-border tw-border-[#dfe2e6] tw-flex tw-rounded-t-[0.5rem] tw-items-center tw-justify-between tw-px-4 tw-gap-3`}
          style={{
            boxShadow:
              "0 3px 3px -2px rgba(39,44,51,.1), 0 3px 4px 0 rgba(39,44,51,.04), 0 1px 8px 0 rgba(39,44,51,.02)",
          }}
          onClick={() => selectTab(request?.id)}
        >
          <div className="tw-flex tw-gap-2 tw-items-center">
            <div
              className={`tw-rounded-full ${request?.b_color} tw-w-[50px] tw-h-[50px] tw-flex tw-justify-center tw-items-center`}
            >
              <request.icon
                size={25}
                className={`!tw-font-bold ${request?.t_color}`}
              />
            </div>
            <span className="tw-text-[15px] tw-text-[rgb(39, 44, 51)] tw-font-[400] tw-leading-[19.5px] tw-font-helvetica">
              {request.label}
            </span>
          </div>
          <span className="tw-text-[14px] tw-leading-[19.5px] tw-text-[rgba(39, 44, 51, 0.5)] tw-font-[400] tw-font-Roboto">
           {/* array length */}
          </span>
        </div>
      ))}
    </div>
  );
};

export default TabCard;