import { useState, useEffect, useMemo } from "react";
import CreditSummary from "../../../components/Admin/Community/CreditSummary";
import { useGetAllCredit } from "../../../redux/hooks/getHooks";
import DrawerBox from "../../../utils/Drawer/DrawerBox";
import OrderHeader from "./OrderHeader";
import { IsErrorLoader, IsLoadingLoader } from "../../../utils/Loader";
import OrderTable from "../../../components/Admin/Community/OrderTable";

import SearchBar from "../../../utils/SearchBar";
import TabCard from "../../../components/Admin/Community/TabCard";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';


// const filterData = [
//     { value: "all", label: "All" },
//     { value: "paid", label: "Paid" },
//     { value: "unpaid", label: "UnPaid" },
//     { value: "fulfilled", label: "Fulfilled" },
//     { value: "unfulfilled", label: "Unfulfilled" }
// ]


const tabData = [
  {
    id: 'repaid',
    label: "Repaid",
    icon: CreditCardIcon,
    b_color: "tw-bg-amber-100",
    t_color: "tw-text-amber-500",
  },
  {
    id: 'instant_payment',
    label: "Instant Payment",
    icon: CardMembershipOutlinedIcon,
    b_color: "tw-bg-cyan-100",
    t_color: "tw-text-cyan-400",
  },
];


export default function TransactionScreen({ handleClick }) {

  //react hooks
  const [open, setOpen] = useState(false);
  const [dataDetail, setDataDetail] = useState(false);

  const [filteredData, setFilteredData] = useState([]);

  const [currentTab, setCurrentTab] = useState("repaid")


  const [filterValue, setFilterValue] = useState(currentTab);



  //external hooks
  const getOrders = useGetAllCredit(1, currentTab);
  const order_page_2 = useGetAllCredit(2, currentTab);
  const order_page_3 = useGetAllCredit(3, currentTab);
  const order_page_4 = useGetAllCredit(4, currentTab);



  


  const totalOrders = getOrders?.data?.data?.data
  ?.concat(order_page_2?.data?.data?.data || [])
  ?.concat(order_page_3?.data?.data?.data || [])
  ?.concat(order_page_4?.data?.data?.data || []);


  const orders = useMemo(()=>{
    const trans = totalOrders?.length ? totalOrders?.filter((item) => item?.status==="repaid" || item?.status==="instant payment"): []

    // console.log(trans)

    return currentTab === 1 ? trans?.filter(item=>item?.status) : trans?.filter(item=>item?.status==="instant payment")

  }, [totalOrders, currentTab])



  //drawer functions==============================
  const handleOpen = (_, data) => {
    setDataDetail(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //========================================================


  const handleFilter=(val)=>{

    let updatedData = [];

    if (val.length) {
      updatedData = orders?.filter((item) => {
        const startsWith = (item?.user?.first_name +" "+item?.user?.last_name)
          ?.toLowerCase()
          .startsWith(val.toLowerCase());

        const includes = (item?.user?.first_name +" "+ item?.user?.last_name)
          ?.toLowerCase()
          .includes(val.toLowerCase());
        const includesOrderId = (item?.id)
          ?.toLowerCase()
          .includes(val.toLowerCase());

        if (startsWith) {
          return startsWith;
        } else if (!startsWith && includes) {
          return includes;
        } else if(includesOrderId){
            return includesOrderId;
        }
         else return null;
      });

      setFilteredData(updatedData);
    }
    else{
        setFilteredData(orders);
        setFilterValue("all")
    }
}


const selectTab = (value) => {
  setCurrentTab(value);
};




const tableData = useMemo(()=>(
    filteredData?.length ? filteredData : orders
), [orders, filteredData])



  return (
    <>
      <main>
      <div className="px-lg-5 d-flex justify-content-between flex-wrap">
      <div className="d-flex justify-content-between ">
                    {/* <p className=''>Transaction</p> */}
                    {/* <p className=''>Quest Transaction</p> */}

              </div>
        </div>
        <SearchBar handleFilter={handleFilter} />
        <OrderHeader role={"Transactions"}/>
        <TabCard selectTab={selectTab} tabData={tabData} currentTab={currentTab}/>
        {getOrders.isLoading ? (
          <IsLoadingLoader />
        ) : getOrders.isError ? (
          <IsErrorLoader />
        ) : (
          tableData?.length?(
            <OrderTable rows={(tableData)?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))} handleOpen={handleOpen} />
          ): (
            <main
            className="d-flex align-items-center justify-content-center flex-column border"
            style={{ height: "25vh", width: "100%" }}
          >
            <h4 className="text-muted"><i>No Data Available</i></h4>
          </main>
          )
        )}
      </main>

      <DrawerBox open={open} size={"50rem"} handleClose={handleClose}>
        <CreditSummary
          order={dataDetail}
          handleClick={handleClick}
          handleCloseSummaryModal={handleClose}
        />
      </DrawerBox>
    </>
  );
}
