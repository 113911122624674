import React from "react";
import Title from "../../../../components/core/shared/Title";
import ShoppingCategory from "../../../../components/core/shared/shopping_category/ShoppingCategory";

const OnlineMarket = () => {
  return (
    <>
      <main>
        <div className="tw-flex tw-justify-center">
          <Title title={"Select Categories"} />
        </div>
        <div className="tw-mt-4">
          <ShoppingCategory />
        </div>
      </main>
    </>
  );
};

export default OnlineMarket;
