import React from "react";
import medication from "../../../../assets/medication.png";
import clsx from "clsx";
import CategoryCard from "./CategoryCard";

const ShoppingCategory = () => {
  return (
    <>
      <main>
        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 gap-4">
          {[1, 2, 3, 5]?.map((category, index) => (
            <CategoryCard
              key={index + "___category"}
              category={{ img: medication }}
            />
          ))}
        </div>
      </main>
    </>
  );
};

export default ShoppingCategory;
