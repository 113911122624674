import { useState } from "react";
import CreditSummary from "../../../components/Admin/Community/CreditSummary";
import { useGetAllCredit } from "../../../redux/hooks/getHooks";
import DrawerBox from "../../../utils/Drawer/DrawerBox";
import OrderHeader from "./OrderHeader";
import { IsErrorLoader, IsLoadingLoader } from "../../../utils/Loader";
import OrderTable from "../../../components/Admin/Community/OrderTable";
import { ActiveNotificationIcon } from "../../../icons/icons";
import SearchBar from "../../../utils/SearchBar";
import { Badge, Select } from "antd";


const filterData = [
    { value: "all", label: "All" },
    { value: "pending", label: "Pending" },
    { value: "order in progress", label: "Order In Progress" },
    { value: "order delivered", label: "Order Delivered" },
]

export default function OrderScreen({ handleClick }) {

  const [filterValue, setFilterValue] = useState("all");

  
  const getOrders = useGetAllCredit(1, filterValue);
  const order_page_2 = useGetAllCredit(2, filterValue);
  const order_page_3 = useGetAllCredit(3, filterValue);
  const order_page_4 = useGetAllCredit(4, filterValue);

  const totalOrders = getOrders?.data?.data?.data
    ?.concat(order_page_2?.data?.data?.data || [])
    ?.concat(order_page_3?.data?.data?.data || [])
    ?.concat(order_page_4?.data?.data?.data || []);

  // console.log(totalOrders)

  const orders = totalOrders?.filter(
    (item) => item?.status !== "repaid" || item?.status !== "instant payment"
  );
  const [open, setOpen] = useState(false);
  const [dataDetail, setDataDetail] = useState(false);

  const [filteredData, setFilteredData] = useState();

  //drawer functions==============================
  const handleOpen = (_, data) => {
    setDataDetail(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //===================================

  //

  const handleFilter = (val) => {
    let updatedData = [];

    if (val.length) {
      updatedData = orders?.filter((item) => {
        const startsWith = (
          item?.user?.first_name +
          " " +
          item?.user?.last_name
        )
          ?.toLowerCase()
          .startsWith(val.toLowerCase());

        const includes = (
          item?.user?.first_name + " " + item?.user?.last_name || item?.id
        )
          ?.toLowerCase()
          .includes(val.toLowerCase());
        const includesOrderId = item?.id
          ?.toLowerCase()
          .includes(val.toLowerCase());

        if (startsWith) {
          return startsWith;
        } else if (!startsWith && includes) {
          return includes;
        } else if (includesOrderId) {
          return includesOrderId;
        } else return null;
      });

      setFilteredData(updatedData);
    }
  };



  const handleChange = (value) => {
    setFilterValue(value);
  };



  return (
    <>
      <main>
        <div className="px-lg-5 d-flex justify-content-between flex-wrap">
          <p className="fw-semibold">
            New Order Request
            {orders?.length ? (
              <Badge
                size="small"
                color="orange"
                count={
                  orders?.filter((item) => item?.status === "pending")?.length
                }
                onClick={() =>
                  setFilteredData(
                    orders?.filter((item) => item?.status === "pending")
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <ActiveNotificationIcon />
              </Badge>
            ) : (
              <ActiveNotificationIcon />
            )}
          </p>
        </div>
        <div className="tw-flex tw-justify-between tw-flex-wrap">
          <SearchBar handleFilter={handleFilter} />
          <Select
      defaultValue={filterValue}
      onChange={handleChange}
      options={filterData}
    />
{/* <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">
              Filter
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
            
              value={filterValue}
              onChange={handleChange}
            >
                {
                    filterData?.map((item, index)=>(
                        <FormControlLabel key={index+"__filter_by"} value={item?.value} control={<Radio size="small"/>} label={item?.label} />
                    ))
                }
            </RadioGroup>
          </FormControl> */}
</div>
        <OrderHeader role={"Order"} />
        {getOrders.isLoading ? (
          <IsLoadingLoader />
        ) : getOrders.isError ? (
          <IsErrorLoader />
        ) : (
          <OrderTable
            rows={(filteredData ?? orders)?.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            )}
            handleOpen={handleOpen}
          />
        )}
      </main>

      <DrawerBox open={open} handleClose={handleClose} size={550}>
        <CreditSummary
          order={dataDetail}
          handleClick={handleClick}
          handleCloseSummaryModal={handleClose}
        />
      </DrawerBox>
    </>
  );
}
