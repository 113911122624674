import React, { useState, useEffect } from "react";
import pharmacy from "../../../assets/pharmacy.png";
import onlineMarket from "../../../assets/cart.png";
import bill_img from "../../../assets/bill-img.png";
import ShoppingHistory from "./ShoppingHistory";
import ModalDialogBox from "../../ModalDialogBox/ModalDialogBox";
import OnlineMarketPlace from "../OnlineMarketPlace/OnlineMarketPlace";
import Pharmacy from "../../Pharmacy/Pharmacy";
import Header from "../Header/Header";
import { useGetUserOrder } from "../../../redux/hooks/getHooks";
import { useUserData } from "../../hooks/useUserData";
import { formatNumber } from "../../../utils/formatNumber";
import SubscriptionPlan from "../../Subscription/SubscriptionPlan";
import moment from "moment";
import { IsErrorLoader, IsLoadingLoader } from "../../../utils/Loader";
import FormControl from "@mui/material/FormControl";
import { Select, MenuItem, InputLabel, TextField, Button } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import PayContainer from "./PayContainer";
import SearchIcon from "@mui/icons-material/Search";
import { useResponsiveSize } from "../../hooks/useResponsiveSize.js";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes/route.js";
import { useSelector, useDispatch } from "react-redux";
import { productAction } from "../../../redux/slice/productSlice";
import BillDetail from "./BillDetail.jsx";

function ShopNow({ handleClick }) {
  const [open, setOpen] = useState({ status: false, type: "", data: null });
  const [searchFilteredData, setSearchFilteredData] = useState();
  const [filteredData, setFilteredData] = useState();
  const [filteredSelected, setFilteredSelected] = useState("");
  const [confirmExistingOrder, setConfirmExistingOrder] = useState(false);

  const [smBuyNow, setSMBuyNow] = useState(false);

  const [openBillModal, setOpenBillModal] = useState(false);

  const [billToDo, setBillToDo] = useState("");

  const { allProducts } = useSelector((state) => state.product);

  const [modal, contextHolder] = Modal.useModal({});

  const navigate = useNavigate({});

  const dispatch = useDispatch({});

  const responsiveSize = useResponsiveSize({});

  const getPendingOrders = useGetUserOrder("all");

  const orders = getPendingOrders?.data?.data?.data;

  const { data } = useUserData();

  const hasSubscription = data?.subscriptions?.length > 0;

  const sessionKey = data?.id + "_product";

  useEffect(() => {
    if (localStorage.billData) {
      navigate(ROUTES.bills);
    }
  }, []);

  const handleOpenDialog = (type, order) => {
    if (type === "marketplace") {
      if (allProducts?.products?.length) {
        setConfirmExistingOrder(true);
      } else if (data?.subscriptions.length) {
        setOpen({ status: true, type: type, data: order });
      } else {
        continueSubscription();
      }
    } else if (type === "bills") {
      setOpenBillModal(true);
    } else {
      setOpen({ status: true, type: type, data: order });
    }
  };

  const handleClose = () => {
    setOpen({ ...open, status: false });
  };

  //image width style
  const imageStyle = {
    width: "70%",
  };

  //calculating the next due date here...
  //   const joinDate = "2023-12-10T07:18:06.518Z"; //dommy date

  //   const formatNextDueDate = (joinDate, interval, durationString) => {
  //     return moment(joinDate)
  //       .add(interval, durationString)
  //       .format("DD MMMM, YYYY");
  //   };

  // Accrued dept=========================

  const credit = orders?.filter((item) => item?.status === "order delivered");

  const currentDept = credit
    ?.reduce((acc, item) => acc + parseFloat(item?.total_amount), 0)
    .toFixed(2);

  //=============

  const transactionCount =
    orders?.filter((item) => item?.status !== "pending")?.length ?? 0;

  const headerData = [
    {
      value: data?.subscriptions?.length
        ? data?.subscriptions[0]["plan"]["name"]
        : "No Subscription",
      label: "Subscription plan",
      key: "upgrade_subscription_plan",
      purpose: data?.subscriptions?.length ? "Change" : "Subscribe",
    },
    {
      value: data?.subscriptions?.length
        ? moment(data?.creditEstimate?.dueDate).format("DD MMMM, YYYY")
        : "No Due Date",
      label: "Subscription ends on",
      key: "next_due_date",
      // purpose: data?.subscriptions?.length ? "Pay" : null,
      purpose: "",
    },

    {
      value: hasSubscription
        ? formatNumber(
            parseFloat(data?.subscriptions[0]?.plan?.credit_limit ?? 0)
          )
        : 0,
      label: "Max. monthly credit",
      key: "max_monthly_credit",
      purpose: "",
    },
    {
      value: hasSubscription
        ? formatNumber(parseFloat(data?.creditEstimate?.remainingCredit ?? 0))
        : 0,
      label: "Available credit",
      key: "available_credit",
      purpose: "",
    },

    {
      value: formatNumber(currentDept ?? 0),
      label: "Accrued Debt",
      key: "accrued_dept",
      purpose: currentDept ? "Pay" : null,
    },

    {
      value: hasSubscription
        ? transactionCount +
          "/" +
          data?.subscriptions[0]?.plan?.credit_times_limit
        : 0,
      label: "Transaction count",
      key: "transaction_count",
      purpose: "",
    },
  ];

  useEffect(() => {
    setFilteredData(orders);
  }, [orders]);

  //search filter function==============
  const handleFilter = (val) => {
    let updatedData = [];

    // if (val.length) {
    updatedData = orders?.filter((item) => {
      const includesName = item?.products.some((product) =>
        product?.name?.toLowerCase().includes(val.toLowerCase())
      );

      const includes = item?.market_place?.name
        ?.toLowerCase()
        .includes(val.toLowerCase());
      if (includesName) {
        return includesName;
      }
      if (includes) {
        return includes;
      } else return null;
    });

    setSearchFilteredData(updatedData);
    // }
  };
  //

  //filtered by paid or unpaid=============
  const handleChange = (event) => {
    const val = event.target.value;
    setFilteredSelected(event.target.value);

    let filteredUser = orders?.filter((item) => {
      const paid = item?.status === "repaid";
      const unpaid = item?.status !== "repaid";
      if (val === "paid") {
        return paid;
      } else if (val === "unpaid") {
        return unpaid;
      } else return null;
    });

    setFilteredData(filteredUser);
  };
  //=================

  //confirmation to continue to subscription
  const continueSubscription = () => {
    modal.confirm({
      title: "Important Notice",
      icon: <ExclamationCircleOutlined />,
      content: `You don't have an active subscription. kindly subscribe to buy on credit`,
      okText: "Continue",
      cancelText: "Cancel",
      onOk: () => {
        navigate(ROUTES.subscription);
      },
    });
  };

  const createNewOrder = () => {
    sessionStorage.removeItem(sessionKey);

    dispatch(productAction.setAllProducts({}));

    // handleOpenDialog("marketplace")
    setOpen({ status: true, type: "marketplace" });
    setConfirmExistingOrder(false);
  };

  const handleSelectBill = (val) => {
    setBillToDo(val);

    setOpenBillModal(false);
  };

  return (
    <>
      {
        <main>
          <section className="mx-2 mx-lg-5 header_title text-center">
            <Header data={headerData} handleOpenDialog={handleOpenDialog} />
          </section>
          <section className="container">
            <div className="row justify-content-center">
              <div
                className="md:tw-hidden col-lg-5 col-md-6 col-sm-12 text-center tw-flex tw-justify-center tw-items-center tw-flex-col"
                style={{
                  marginTop: "4vh",
                }}
              >
                {/* <div>
                  <h5 className="fw-bold">Shop across Stores</h5>
                  <p>Pharmacies, Jumia, Konga, and Other stores</p>
                </div> */}
                <div
                  className=" my-2 text-center border tw-flex tw-items-center tw-justify-center tw-flex-col"
                  style={{
                    backgroundColor: "#F7E7CE",
                    borderRadius: "20px",
                    padding: "10px",
                    width: "60%",
                  }}
                >
                  <img
                    src={onlineMarket}
                    alt={`Online Marketplace`}
                    className="img-fluid"
                    style={imageStyle}
                  />
                  <div>
                    <Button
                      className="mt-3"
                      variant="contained"
                      // onClick={() => handleOpenDialog("marketplace")}
                      onClick={() => setSMBuyNow(true)}
                      sx={{
                        backgroundColor: "#323333",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#323333",
                        },
                      }}
                    >
                      Buy Now
                    </Button>
                  </div>
                </div>
              </div>
              <div
                className="tw-hidden col-lg-5 col-md-6 col-sm-12 text-center md:tw-flex tw-justify-center tw-items-center tw-flex-col"
                style={{
                  marginTop: "4vh",
                }}
              >
                <div>
                  <h5 className="fw-bold">Shop across Stores</h5>
                  <p>Pharmacies, Jumia, Konga, and Other stores</p>
                </div>
                <div
                  className=" my-2 text-center border tw-flex tw-items-center tw-justify-center tw-flex-col"
                  style={{
                    backgroundColor: "#F7E7CE",
                    borderRadius: "20px",
                    padding: "10px",
                    width: "60%",
                  }}
                >
                  <img
                    src={onlineMarket}
                    alt={`Online Marketplace`}
                    className="img-fluid"
                    style={imageStyle}
                  />
                  <div>
                    <Button
                      className="mt-3"
                      variant="contained"
                      onClick={() =>
                        // navigate("/shopper")
                        handleOpenDialog("marketplace")
                      }
                      sx={{
                        backgroundColor: "#323333",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#323333",
                        },
                      }}
                    >
                      Start Now
                    </Button>
                  </div>
                </div>
              </div>
              <div
                className="tw-hidden col-lg-5 col-md-6 col-sm-12 text-center md:tw-flex tw-justify-center tw-items-center tw-flex-col"
                style={{
                  marginTop: "4vh",
                }}
              >
                <div>
                  <h5 className="fw-bold">
                    Bills (electricity, Airtime and more)
                  </h5>
                  <p>Pays subscription for cable tv, Data, and others</p>
                </div>
                <div
                  className=" my-2 text-center border tw-flex tw-items-center tw-justify-center tw-flex-col"
                  style={{
                    backgroundColor: "#F7E7CE",
                    borderRadius: "20px",
                    padding: "10px",
                    width: "60%",
                  }}
                >
                  <img
                    src={bill_img}
                    alt={`bills`}
                    className="img-fluid tw-object-cover tw-items-center"
                    style={imageStyle}
                  />
                  <div>
                    <Button
                      className="mt-3"
                      variant="contained"
                      onClick={() => navigate("bills")}
                      sx={{
                        backgroundColor: "#323333",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#323333",
                        },
                      }}
                    >
                      Buy Now
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="bg-whit pt-4 pt-lg-5 mx-3">
            <h5 className="text-center my-2" style={{ fontSize: "3vh" }}>
              Shopping History
            </h5>
            {getPendingOrders.isLoading ? (
              <IsLoadingLoader />
            ) : getPendingOrders.isError ? (
              <IsErrorLoader />
            ) : // <ShoppingHistory rows={orders} />
            orders?.length ? (
              <>
                <div className="col-lg-10 mx-auto d-flex justify-content-between">
                  <div>
                    <div className="d-flex gap-3 align-items-center">
                      <TextField
                        type="text"
                        placeholder="Search store or order"
                        fullWidth
                        size="small"
                        InputProps={{
                          style: {
                            fontSize: "14px",
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => handleFilter(e.target.value)}
                      />

                      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="demo-select-small-label">
                          Filter
                        </InputLabel>
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={filteredSelected}
                          label="Filter"
                          onChange={handleChange}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {["Paid", "Unpaid"]?.map((item, index) => (
                            <MenuItem
                              key={index + "___filter_by"}
                              value={item.toLowerCase()}
                            >
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
                {/* <UserOrderTable rows={orders} /> */}
                <ShoppingHistory
                  rows={searchFilteredData ?? filteredData ?? orders}
                  handleOpenDialog={handleOpenDialog}
                />
              </>
            ) : (
              <p className={`card-tex text-center my-2 text-muted fs-5`}>
                <i>You have not made any purchase yet.</i>
              </p>
            )}
          </section>
        </main>
      }

      {open.status && open?.type === "pay" && open?.data?.is_bill ? (
        <Modal
          centered
          open={open?.status}
          onCancel={handleClose}
          footer={null}
        >
          {" "}
          <BillDetail
            billData={open?.data}
            handleClick={handleClick}
            handleClose={handleClose}
          />
        </Modal>
      ) : (
        <ModalDialogBox
          open={open.status}
          handleClose={handleClose}
          maxWidth={
            open?.type === "marketplace" &&
            (responsiveSize === "md" || responsiveSize === "lg")
              ? "sm"
              : "md"
          }
          modalSize={
            open?.type === "pay"
              ? !responsiveSize === "lg"
                ? true
                : false
              : false
          }
        >
          {open.type === "marketplace" ? (
            <OnlineMarketPlace handleClick={handleClick} />
          ) : open.type === "pharmacy" ? (
            <Pharmacy />
          ) : open.type === "upgradePlan" ? (
            <SubscriptionPlan role={"user"} handleClose={handleClose} />
          ) : (
            open?.type === "pay" && (
              <>
                <PayContainer
                  order={open?.data}
                  dueDate={data?.creditEstimate?.dueDate}
                  handleClose={handleClose}
                  handleClick={handleClick}
                />
              </>
            )
          )}
        </ModalDialogBox>
      )}

      <Modal
        title="Important Notice!!!"
        centered
        open={confirmExistingOrder}
        onCancel={() => setConfirmExistingOrder(false)}
        footer={[
          <Button
            key="back"
            onClick={createNewOrder}
            sx={{
              border: "1px solid #033F4B",
              color: "#033F4B",
              marginRight: "1vh",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#033F4B",
                color: "#fff",
              },
            }}
          >
            Create New Order
          </Button>,
          <Button
            key="continue_with_existing"
            type="primary"
            onClick={() => navigate(ROUTES.cart)}
            sx={{
              backgroundColor: "#033F4B",
              color: "white",
              marginLeft: "1vh",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#033F4B",
              },
            }}
          >
            Existing order
          </Button>,
        ]}
      >
        You have an Existing order on your cart, Would you like to continue with
        Existing order or Create new order
      </Modal>

      <Modal
        open={openBillModal}
        onCancel={() => setOpenBillModal(false)}
        footer={null}
      >
        <section className="mt-5 mb-3">
          <div className="d-flex flex-column gap-3">
            <Button
              variant="outlined"
              className="border-secondary text-secondary"
              sx={{
                borderRadius: "20px",
                textTransform: "capitalize",
              }}
              onClick={() => handleSelectBill("electricity")}
            >
              Buy Electricity
            </Button>
            <Button
              variant="outlined"
              className="border-secondary text-secondary"
              sx={{
                borderRadius: "20px",
                textTransform: "capitalize",
              }}
              onClick={() => handleSelectBill("airtime")}
            >
              Buy Airtime
            </Button>
            <Button
              variant="outlined"
              className="border-secondary text-secondary"
              sx={{
                borderRadius: "20px",
                textTransform: "capitalize",
              }}
              onClick={() => handleSelectBill("data")}
            >
              Buy Data
            </Button>
            <Button
              variant="outlined"
              className="border-secondary text-secondary"
              sx={{
                borderRadius: "20px",
                textTransform: "capitalize",
              }}
              onClick={() => handleSelectBill("cable")}
            >
              Pay for Cable TV Subscription
            </Button>
          </div>
        </section>
      </Modal>

      {/* On small screen, modal to continue Shopping */}
      <Modal
        centered
        open={smBuyNow}
        footer={null}
        onCancel={() => setSMBuyNow(false)}
      >
        <div className="tw-flex tw-flex-col tw-gap-7 tw-mt-8 tw-mb-3">
          <div
            className="tw-border tw-rounded tw-p-3 tw-cursor-pointer"
            onClick={() => handleOpenDialog("marketplace")}
          >
            <h2 className="tw-text-xl tw-font-semibold">Shop across Stores</h2>
            <p>Pharmacies, Jumia, Konga, and Other stores</p>
          </div>
          <div
            className="tw-border tw-rounded tw-p-3 tw-cursor-pointer"
            onClick={() => navigate("bills")}
          >
            <h2 className="tw-text-xl tw-font-semibold">
              Bills <small>(Electricity, Airtime and more)</small>
            </h2>
            <p>Pays subscription for cable tv, Data, and others</p>
          </div>
        </div>
      </Modal>

      {contextHolder}
    </>
  );
}

export default ShopNow;
