import clsx from "clsx";
import Title from "../../../../components/core/shared/Title";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ShopView = () => {
  const [activeStore, setActiveStore] = useState(0);

  const stores = [
    { name: "Supermarkets" },
    { name: "Online Markets" },
    { name: "Bill" },
    { name: "Pharmacy" },
    { name: "Food stuff" },
  ];
  const navigate = useNavigate({});

  const selectStore = (store, index) => {
    setActiveStore(index);

    if (store?.name === "Bill") {
      navigate("/shop/bills");
    } else {
      navigate(`store?name=${store?.name}`);
    }
  };

  return (
    <>
      <main>
        <div className="tw-flex tw-justify-center tw-my-8">
          <Title
            title="Stores"
            sub="Select store to start shopping"
            position={"center"}
          />
        </div>

        <div className="tw-flex gap-3 tw-justify-center">
          {stores.map((store, index) => {
            return (
              <div
                key={index + "____store"}
                className={clsx(
                  "tw-inline-flex tw-rounded-full tw-border tw-py-2 tw-px-5 tw-text-center tw-cursor-pointer tw-transition-all tw-duration-300",
                  activeStore === index
                    ? "tw-border-black"
                    : "tw-border-default-400"
                )}
                onClick={() => selectStore(store, index)}
              >
                {store?.name}
              </div>
            );
          })}
        </div>
      </main>
    </>
  );
};

export default ShopView;
