import { Button } from "antd";
import React from "react";
import { motion } from "framer-motion";
import { useResponsiveSize } from "../../components/hooks/useResponsiveSize";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function BillMenu({ setBill, bill, toggleBillMenu }) {
  const responsiveSize = useResponsiveSize({});

  const handleSelectBill = (val) => {
    setBill(val);
    responsiveSize === "sm" && toggleBillMenu();
  };

  const navigate = useNavigate();

  const onBack = () => {
    navigate(-1);
  };

  return (
    <>
      <motion.div
        className="border-end"
        initial={{ x: -300 }}
        animate={{ x: 0 }}
        exit={{ x: -300 }}
        transition={{ duration: 0.5 }}
        style={{
          position: "absolute",
          minHeight: "60vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          width: "17rem",
          zIndex: 10,
        }}
      >
        <div className="tw-p-3 float-start">
          <Button shape="circle" icon={<FaArrowLeft />} onClick={onBack} />
        </div>
        <div className="d-flex flex-column gap-3 pe-3">
          <Button
            className={`tw-py-7 tw-rounded-full tw-bg-white ${
              bill === "electricity" && "border-secondary"
            }`}
            size={"large"}
            style={{
              textTransform: "capitalize",
            }}
            onClick={() => handleSelectBill("electricity")}
          >
            Buy Electricity
          </Button>
          <Button
            className={`tw-py-7 tw-rounded-full tw-bg-white ${
              bill === "airtime" && "border-secondary"
            }`}
            size={"large"}
            style={{
              textTransform: "capitalize",
            }}
            onClick={() => handleSelectBill("airtime")}
          >
            Buy Airtime
          </Button>
          <Button
            className={`tw-py-7 tw-rounded-full tw-bg-white ${
              bill === "data" && "border-secondary"
            }`}
            size={"large"}
            style={{
              textTransform: "capitalize",
            }}
            onClick={() => handleSelectBill("data")}
          >
            Buy Data
          </Button>
          <Button
            className={`tw-py-7 tw-rounded-full tw-bg-white ${
              bill === "cable" && "border-secondary"
            }`}
            size={"large"}
            style={{
              textTransform: "capitalize",
            }}
            onClick={() => handleSelectBill("cable")}
          >
            Pay for Cable TV Subscription
          </Button>
        </div>
      </motion.div>
    </>
  );
}
