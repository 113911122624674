import { useEffect, useState } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import Signup from "./pages/Signup";
import AuthLayout from "./layouts/AuthLayout/AuthLayout";
import AOS from "aos";
import "aos/dist/aos.css";
import HomeLayout from "./layouts/HomeLayout/HomeLayout";
import Home from "./pages/Home";
import CustomizedSnackbars from "./components/snackbar/snackbar";
import ShopLayout from "./layouts/ShopLayout/ShopLayout";
import ShopNow from "./components/Shop/ShopNow/ShopNow";
import WelcomeAddress from "./components/Templates/WelcomeAddress/WelcomeAddress";
import EmailWelcomeTemplate from "./components/Templates/EmailWelcomeTemplate/EmailWelcomeTemplate";
import BrandWelcomeTemplate from "./components/Templates/BrandWelcomeTemplate/BrandWelcomeTemplate";
import EmailifyTemplate from "./components/Templates/EmailifyTemplate/EmailifyTemplate";
import Subscription from "./components/Subscription/Subscription";
import SubscriptionPlan from "./components/Subscription/SubscriptionPlan";
import Request from "./components/Request/Request";
import UserTransactionHistory from "./components/UserTransactionHistory/UserTransactionHistory";
import Credit from "./components/Credit/Credit";
import Login from "./pages/Login";
import Kyc from "./pages/Kyc";
import KycVerification from "./components/Auth/Kyc/KycVerification/KycVerification";
import KycLayout from "./layouts/KycLayout/KycLayout";
import LandingPageLayout from "./layouts/LandingPageLayout/LandingPageLayout";
import BankStatement from "./components/Auth/Kyc/KycVerification/BankStatement/BankStatement";
import Summary from "./components/Auth/Kyc/KycVerification/Summary/Summary";
import VerifyAccount from "./pages/VerifyAccount";
import NotFound from "./pages/NotFound";
import ProtectedRoute from "./components/RouteGuard/ProtectedRoute";
import PublicRoute from "./components/RouteGuard/PublicRoute";
import ForgotPsw from "./pages/ForgotPsw";
import ResetPsw from "./pages/ResetPsw";
import { ROUTES } from "./routes/route";
import NumberVerification from "./components/Auth/NumberVerification/NumberVerification";
import Dashboard from "./pages/Admin/Dashboard";
import Profile from "./pages/Profile";
import AdminLogin from "./pages/Admin/AdminLogin";
import Cart from "./components/Shop/Cart/Cart";
import Checkout from "./components/Shop/Checkout/Checkout";
import MerchantLogin from "./pages/Merchant/MerchantLogin";
import MerchantLayout from "./layouts/MerchantLayout/MerchantLayout";
import MerchantDashboard from "./pages/Merchant/MerchantDashboard";
import Transaction from "./screens/Merchant/MerchantDashboardScreen/Transaction/Transaction";
import Settlement from "./screens/Merchant/MerchantDashboardScreen/Settlement/Settlement";
import AdminProtectedRoute from "./components/RouteGuard/AdminProtectedRoute";
import MerchantProtectedRoute from "./components/RouteGuard/MerchantProtectedRoute";
import AdminLayout from "./layouts/AdminLayout/AdminLayout";
import CommunityLayout from "./layouts/AdminLayout/CommunityLayout";
import AdminProfile from "./pages/Admin/AdminProfile";
import Community from "./pages/Admin/Community";
import CommunityOrder from "./pages/Admin/CommunityOrder";
import Merchant from "./pages/Admin/Merchant";
import AdminSubscriptionPlans from "./pages/Admin/AdminSubscriptionPlans";
import AdminMarketPlace from "./pages/Admin/AdminMarketPlace";
import UserSubscription from "./pages/UserSubscription";
import CommunityMember from "./pages/Admin/CommunityMember";
import CommunityTransaction from "./pages/Admin/CommunityTransaction";
import AdminTransactionHistory from "./pages/Admin/AdminTransactionHistory";
import VerifyPayment from "./pages/VerifyPayment";
import Contact from "./pages/Contact";
import Privacy from "./pages/Privacy";
import { Modal, message as antdMessage } from "antd";
import UpgradeSubscription from "./pages/UpgradeSubscription";
import AdminUpgradeRequest from "./pages/Admin/AdminUpgradeRequest";
import UtilityPages from "./pages/utility_pages";
import Emandate from "./pages/Admin/Emandate";
import ShopNeeds from "./pages/Shops";
import EmandateLayout from "./layouts/emandateLayout/EmandateLayout";
import EmandateDashboard from "./pages/Emandates/dashboard/Dashboard";
import EmandateLoanHistory from "./pages/Emandates/loan/Loan";
import EmandateProtectedRoute from "./components/RouteGuard/EmandateProtectedRoute";
import UserLoanApprovalPages from "./pages/Emandates/user_pages_for_loan_approval/UserLoanApprovalPages";
import ShopView from "./pages/Shops/shopper/shop-view/ShopView";
import StoreView from "./pages/Shops/shopper/store-view/StoreView";

// export const uuid = crypto.randomUUID();

function App() {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [origin, setOrigin] = useState(null);

  const [messageApi, contextHolder] = antdMessage.useMessage();

  const handleClick = (message, severity, origin, muiAlert) => {
    if (muiAlert) {
      setOpen(true);
      setSeverity(severity);
      setMessage(message);
      setOrigin(origin);
    } else {
      messageApi.open({
        type: severity,
        content: message,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    AOS.refresh();
  }, []);

  return (
    <>
      {contextHolder}
      <Routes>
        <Route path="/" element={<PublicRoute />}>
          <Route path="/" element={<LandingPageLayout />}>
            <Route path="" element={<LandingPage />} />
            <Route
              path="bill"
              element={<UtilityPages handleClick={handleClick} />}
            />
            <Route
              path="shop-your-needs"
              element={<ShopNeeds handleClick={handleClick} />}
            />
            <Route path="contact" element={<Contact />} />
            <Route path="vouch-privacy" element={<Privacy />} />
          </Route>

          {/* <Route path="/" element={<PublicRoute />}> */}
          <Route path="/auth" element={<AuthLayout />}>
            <Route
              path="register"
              element={<Signup handleClick={handleClick} />}
            />
            <Route path="login" element={<Login handleClick={handleClick} />} />
            <Route
              path="forget-password"
              element={<ForgotPsw handleClick={handleClick} />}
            />
            <Route
              path="reset-password/:token"
              element={<ResetPsw handleClick={handleClick} />}
            />
          </Route>
        </Route>

        <Route path="/auth/verify/:token" element={<VerifyAccount />} />
        <Route
          path="/verify_payment"
          element={<VerifyPayment handleClick={handleClick} />}
        />

        <Route path="/" element={<ProtectedRoute handleClick={handleClick} />}>
          <Route path="/" element={<Navigate replace to={"/kyc"} />} />
          {/* <Route path="/welcome" element={<WelcomeAddress />} />
          <Route path="/email-welcome" element={<EmailWelcomeTemplate />} />
          <Route path="/brand-welcome" element={<BrandWelcomeTemplate />} />
          <Route path="/emailify" element={<EmailifyTemplate />} /> */}
          <Route path="/" element={<HomeLayout handleClick={handleClick} />}>
            <Route path="home" element={<Home />} />

            <Route path="shopper" element={<Outlet />}>
              <Route path="" element={<ShopView />} />
              <Route path="store" element={<Outlet />}>
                <Route path="" element={<StoreView />} />
              </Route>
            </Route>
            <Route path="shop" element={<ShopLayout />}>
              <Route path="" element={<ShopNow handleClick={handleClick} />} />

              <Route
                path="verify_payment"
                element={<VerifyPayment handleClick={handleClick} />}
              />

              <Route path="subscription" element={<Outlet />}>
                <Route
                  path=""
                  element={<UserSubscription handleClick={handleClick} />}
                />
                <Route path="successfull" element={<Subscription />} />
                <Route
                  path="upgrade"
                  element={<UpgradeSubscription handleClick={handleClick} />}
                />
              </Route>
              <Route
                path="request"
                element={<Request handleClick={handleClick} />}
              />
              <Route
                path="bills"
                element={<UtilityPages handleClick={handleClick} />}
              />
              <Route
                path="credit"
                element={<Credit handleClick={handleClick} />}
              />
              <Route
                path="transaction_history"
                element={<UserTransactionHistory handleClick={handleClick} />}
              />
              <Route path="cart" element={<Outlet />}>
                <Route path="" element={<Cart handleClick={handleClick} />} />
                <Route
                  path="checkout"
                  element={<Checkout handleClick={handleClick} />}
                />
              </Route>
            </Route>
            <Route
              path="/profile"
              element={<Profile handleClick={handleClick} />}
            />
            <Route path="/kyc" element={<KycVerification />} />
            <Route path="/kyc" element={<KycLayout />}>
              <Route
                path="complete-kyc/*"
                element={<Kyc handleClick={handleClick} />}
              />
              <Route
                path={ROUTES.bank_statement}
                element={<BankStatement handleClick={handleClick} />}
              />
              <Route path={ROUTES.summary} element={<Summary />} />
              <Route
                path={ROUTES.otp_verification}
                element={<NumberVerification handleClick={handleClick} />}
              />
            </Route>
          </Route>
        </Route>
        {/* Admin Routes */}
        <Route path="/admin" element={<Outlet />}>
          <Route path="auth" element={<Outlet />}>
            <Route
              path="login"
              element={<AdminLogin handleClick={handleClick} />}
            />
          </Route>
          <Route
            path=""
            element={<AdminProtectedRoute handleClick={handleClick} />}
          >
            <Route path="" element={<AdminLayout handleClick={handleClick} />}>
              <Route
                path=""
                element={<Dashboard handleClick={handleClick} />}
              />
              <Route
                path="community"
                element={<Community handleClick={handleClick} />}
              />
              <Route
                path="community"
                element={<CommunityLayout handleClick={handleClick} />}
              >
                <Route
                  path="member"
                  element={<CommunityMember handleClick={handleClick} />}
                />
                <Route
                  path="order"
                  element={<CommunityOrder handleClick={handleClick} />}
                />
                <Route
                  path="transaction"
                  element={<CommunityTransaction handleClick={handleClick} />}
                />
                <Route
                  path="transaction-history"
                  element={
                    <AdminTransactionHistory handleClick={handleClick} />
                  }
                />
                <Route
                  path="e-mandate-history"
                  element={<Emandate handleClick={handleClick} />}
                />
              </Route>
              <Route path="merchant" element={<Outlet />}>
                <Route
                  path="merchant"
                  element={<Merchant handleClick={handleClick} />}
                />
                <Route
                  path="payment_settlement"
                  element={<Merchant handleClick={handleClick} />}
                />
              </Route>
              <Route path="profile" element={<AdminProfile />} />
              <Route
                path="plans"
                element={<AdminSubscriptionPlans handleClick={handleClick} />}
              />
              <Route
                path="market_place"
                element={<AdminMarketPlace handleClick={handleClick} />}
              />
              <Route
                path="upgrade_request"
                element={<AdminUpgradeRequest handleClick={handleClick} />}
              />
            </Route>
          </Route>
        </Route>
        <Route
          path="user/e_mandate/:orderId"
          element={<UserLoanApprovalPages />}
        />
        <Route path="emandate" element={<EmandateProtectedRoute />}>
          <Route path="" element={<EmandateLayout handleClick={handleClick} />}>
            <Route
              path=""
              element={<EmandateDashboard handleClick={handleClick} />}
            />
            <Route path="loan" element={<EmandateLoanHistory />} />
          </Route>
        </Route>
        {/* Merchant routes */}
        <Route path="/merchant" element={<Outlet />}>
          <Route
            path="auth/login"
            element={<MerchantLogin handleClick={handleClick} />}
          />
          <Route
            path=""
            element={<MerchantProtectedRoute handleClick={handleClick} />}
          >
            <Route path="" element={<MerchantLayout />}>
              <Route path="" element={<MerchantDashboard />}>
                <Route
                  path=""
                  element={<Transaction handleClick={handleClick} />}
                />
                <Route path="st" element={<Settlement />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <CustomizedSnackbars
        handleClose={handleClose}
        open={open}
        severity={severity}
        message={message}
        origin={origin}
      />
    </>
  );
}

export default App;
