import React, { useEffect, useState } from "react";
import profile from "../../../../assets/avatar.png";
import PersonalInformation from "./PersonalInformation";
import CustomButton from "../../../CustomButton/CustomButton";
import AddressInformation from "./AddressInformation";
import WorkInformation from "./WorkInformation";
import FinancialStAndIncome from "./FinancialStAndIncome";
import {
  useGetAllUsers,
  useGetBankStatement,
} from "../../../../redux/hooks/getHooks";
import { useUpdateKycStatus } from "../../../../redux/mutation/AdminPostMutation";
import ModalDialogBox from "../../../ModalDialogBox/ModalDialogBox";
import StatementDetails from "./StatementDetails";
import NINDetails from "./BvnDetails";
import SetSubscriptionPlan from "../../Community/SetSubscriptionPlan";
import { useQueryClient } from "@tanstack/react-query";
import NIN_IDENTITY from "./NIN_Identity";

function Profile({
  profileData,
  handleClick,
  handleCloseProfileModal,
  handleCloseOptionModal,
}) {
  // const approveKycMutation = useApproveKyc();
  const [open, setOpen] = useState({ status: false, type: "" });
  const [isLoading, setIsLoading] = useState({
    status: false,
    key: "",
    actionStatus: "",
  });

  const updateKycStatusMutation = useUpdateKycStatus(profileData?.id);

  const getAllUsers = useGetAllUsers({});
  const ALLUSERS = getAllUsers?.data?.data?.data;

  const THIS_CUSTOMER = ALLUSERS?.find((user) => user.id === profileData?.id);

  const queryClient = useQueryClient();

  // const getUserBkSummary = useGetUserAccountSummary(THIS_CUSTOMER?.id);

  const getUserBkStatement = useGetBankStatement(profileData?.id);
  const statementDetailsRes = getUserBkStatement?.data?.data;
  const statements = statementDetailsRes?.data?.statement;
  const details = statementDetailsRes?.data?.details;
  const ninDetails = statementDetailsRes?.data?.nin_identities;
  const incomeDetails = statementDetailsRes?.data?.income;
  const bankDetail = statementDetailsRes?.data?.details;
  const userBankName =
    statementDetailsRes?.data?.details?.account?.institution?.name;
  const identities = statementDetailsRes?.data?.identities;

  // console.log(statements)

  const handleOpen = (type) => {
    setOpen({ ...open, status: true, type });
  };

  const handleClose = () => {
    setOpen({ ...open, status: false });
  };

  const handleUpdateKycStatusFn = (key, status) => {
    setIsLoading({ ...isLoading, status: true, key, actionStatus: status });
    const data = {
      key: key,
      status: status,
    };

    updateKycStatusMutation?.mutate(data, {
      onSuccess: (data) => {
        queryClient.invalidateQueries({ queryKey: ["all_users"] });
      },
    });
  };

  useEffect(() => {
    if (updateKycStatusMutation?.isLoading) {
      setIsLoading({ ...isLoading, status: true });
    } else if (updateKycStatusMutation?.isError) {
      setIsLoading({ ...isLoading, status: false, key: "" });
      let msg = updateKycStatusMutation?.error?.response?.data?.message;
      let msg2 = updateKycStatusMutation?.error?.response?.statusText;
      handleClick(msg ?? msg2, "success", {
        vertical: "top",
        horizontal: "bottom",
      });
    } else if (updateKycStatusMutation?.isSuccess) {
      setIsLoading({ ...isLoading, status: false, key: "" });
      handleClick(updateKycStatusMutation?.data?.data?.message, "success", {
        vertical: "top",
        horizontal: "bottom",
      });
      // console.log(updateKycStatusMutation?.data?.data);
    }
  }, [updateKycStatusMutation?.status]);

  //mutation useEffect
  // useEffect(() => {
  //   if (approveKycMutation.isLoading) {
  //     setIsLoading(true);
  //   }
  //   if (approveKycMutation.isSuccess) {
  //     setIsLoading(false);
  //     let res = approveKycMutation?.data?.data;
  //     handleClick(res?.message, res?.success ? "success" : "error");
  //   }
  //   if (approveKycMutation.isError) {
  //     setIsLoading(false);
  //     let msg = approveKycMutation?.error?.response?.data?.message;
  //     handleClick(msg, "error");
  //   }
  // }, [approveKycMutation.status]);

  const profileStyle = {
    backgroundImage: `url(${profileData?.profile_picture ?? profile})`,
    width: "30vh",
    height: "30vh",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderRadius: "50%",
  };

  const handleCloseAllModal = () => {
    handleClose();
    handleCloseProfileModal();
    handleCloseOptionModal();
  };

  return (
    <>
      <main className="col-lg-12 mx-auto order-2">
        <h3 className="text-center">Profile</h3>
        <p className="fw-bold fs-6 text-center">Summary</p>
        <br />
        <section>
          <div className="d-flex justify-content-center align-items-center">
            <div style={profileStyle}></div>
          </div>
          <div className="d-flex items-align-center">
            <span
              className={`alert my-2 mx-auto text-center py-1 ${
                THIS_CUSTOMER?.status ? "alert-success" : "alert-danger"
              } mx-2`}
            >
              {THIS_CUSTOMER?.status
                ? "Account approved"
                : THIS_CUSTOMER?.kyc_status && THIS_CUSTOMER?.is_declined
                ? "KYC is declined"
                : THIS_CUSTOMER?.kyc_status && !THIS_CUSTOMER?.status
                ? "KYC is completed and yet to approve"
                : "KYC is not completed"}
            </span>
          </div>
          <section>
            <PersonalInformation
              data={{
                name: `${THIS_CUSTOMER?.first_name} ${THIS_CUSTOMER?.last_name}`,
                phone: THIS_CUSTOMER?.phone,
                email: THIS_CUSTOMER?.email,
                gender: THIS_CUSTOMER?.bioData?.gender,
                maritalStatus: THIS_CUSTOMER?.bioData?.maritalStatus,
                dob: THIS_CUSTOMER?.bioData?.dob,
                nin: THIS_CUSTOMER?.bioData?.nin,
                dobMatch: THIS_CUSTOMER?.dobMatch,
                ninMatch: THIS_CUSTOMER?.ninMatch,
              }}
              actionFn={handleUpdateKycStatusFn}
              actionLoading={{
                loading: isLoading?.key === "bioData" && isLoading?.status,
                status: isLoading.actionStatus,
              }}
              actionSuccess={THIS_CUSTOMER?.account_status?.bioData}
            />

            <AddressInformation
              data={THIS_CUSTOMER?.contact}
              actionFn={handleUpdateKycStatusFn}
              actionLoading={{
                loading: isLoading?.key === "contact" && isLoading?.status,
                status: isLoading.actionStatus,
              }}
              actionSuccess={THIS_CUSTOMER?.account_status?.contact}
            />
            <WorkInformation
              data={THIS_CUSTOMER?.employmentDetails}
              actionFn={handleUpdateKycStatusFn}
              actionLoading={{
                loading:
                  isLoading?.key === "employmentDetails" && isLoading?.status,
                status: isLoading.actionStatus,
              }}
              actionSuccess={THIS_CUSTOMER?.account_status?.employmentDetails}
            />
            <NIN_IDENTITY
              data={{
                nin: THIS_CUSTOMER?.bioData?.nin,
                bvn: identities?.bvn,
                fullName: identities?.full_name,
              }}
              actionFn={handleUpdateKycStatusFn}
              actionLoading={{
                loading: isLoading?.key === "nin" && isLoading?.status,
                status: isLoading.actionStatus,
              }}
              actionSuccess={THIS_CUSTOMER?.account_status?.ninIdentity}
            />
            <FinancialStAndIncome
              data={{ ...incomeDetails, bank: userBankName }}
              handleOpen={handleOpen}
              actionFn={handleUpdateKycStatusFn}
              actionLoading={{
                loading:
                  isLoading?.key === "bankStatement" && isLoading?.status,
                status: isLoading.actionStatus,
              }}
              actionSuccess={THIS_CUSTOMER?.account_status?.bankStatement}
            />
          </section>
        </section>
        <section className="text-center col-lg-3 mx-auto col-md-6">
          {/* <CustomButton text={THIS_CUSTOMER?.status?"Account Approved": "Yet to be Approved"} disabled={THIS_CUSTOMER?.kycStage<7} loading={isLoading} /> */}
          <CustomButton
            text={"Next"}
            functionFn={() => handleOpen("approve")}
          />
        </section>
      </main>

      <ModalDialogBox
        open={open.status}
        handleClose={handleClose}
        modalSize={true}
      >
        {open.type === "statement_detail" ? (
          <StatementDetails
            rows={statements}
            ownerName={identities?.full_name}
            bankName={details?.account?.institution?.name}
            userId={profileData?.id}
          />
        ) : open.type === "nin_detail" ? (
          <NINDetails details={ninDetails} userId={profileData?.id} />
        ) : (
          open.type === "approve" && (
            <SetSubscriptionPlan
              user={THIS_CUSTOMER}
              handleClick={handleClick}
              handleClose={handleCloseAllModal}
            />
          )
        )}
      </ModalDialogBox>
    </>
  );
}

export default Profile;
