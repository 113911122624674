import React, { useState } from "react";
import ElectricityReceipt from "./ElectricityReceipt";
import AirtimeReceipt from "./AirtimeReceipt";
import DataReceipt from "./DataReceipt";
import CableReceipt from "./CableReceipt";
import { useGetOrderTransactionHistory } from "../../../redux/hooks/getHooks";
import {
  useCancelOrder,
  useInitiateRepayment,
} from "../../../redux/mutation/postMutation";
import { usePaymentModal } from "../../../redux/hooks/zustands/usePaymentModal.js";

export default function BillDetail({ billData, handleClick, handleClose }) {
  const getOrderTrans = useGetOrderTransactionHistory(billData?.id, "user");

  const data = getOrderTrans?.data?.data?.data;

  const paymentMutation = useInitiateRepayment();
  const { openModal: openPaymentModal } = usePaymentModal();

  const cancelOrderMutation = useCancelOrder();

  const [isLoading, setIsLoading] = useState(false);

  const cancelOrder = () => {
    setIsLoading(true);
    cancelOrderMutation.mutate(
      { orderID: billData?.id },
      {
        onError: (error) => {
          setIsLoading(false);
          handleClick(
            "Failed to cancel order",
            "error",
            {
              vertical: "top",
              horizontal: "right",
            },
            true
          );
        },
        onSuccess: () => {
          setIsLoading(false);
          handleClick(
            "Order cancelled successfully",
            "success",
            {
              vertical: "top",
              horizontal: "right",
            },
            true
          );
          // window.location.reload()
          handleClose();
        },
      }
    );
  };

  const continueTransaction = (orderId) => {
    setIsLoading(true);

    paymentMutation.mutate(
      {
        order_id: orderId,
        payment_type: "transfer",
      },
      {
        onSuccess: (res) => {
          setIsLoading(false);
          openPaymentModal(false);

          handleClose();
        },
        onError: (error) => {
          setIsLoading(false);
        },
      }
    );
  };
  return (
    <>
      {billData?.order_name === "electricity" ? (
        <ElectricityReceipt
          data={data?.length ? data[0] : { order: billData }}
          cancelOrder={cancelOrder}
          isLoading={isLoading}
          continueTransaction={continueTransaction}
        />
      ) : billData?.order_name === "airtime" ? (
        <AirtimeReceipt
          data={data?.length ? data[0] : billData}
          cancelOrder={cancelOrder}
          isLoading={isLoading}
          continueTransaction={continueTransaction}
        />
      ) : billData?.order_name === "data" ? (
        <DataReceipt
          data={data?.length ? data[0] : billData}
          cancelOrder={cancelOrder}
          isLoading={isLoading}
          continueTransaction={continueTransaction}
        />
      ) : (
        billData?.order_name === "cable" && (
          <CableReceipt
            data={data?.length ? data[0] : billData}
            cancelOrder={cancelOrder}
            isLoading={isLoading}
            continueTransaction={continueTransaction}
          />
        )
      )}
    </>
  );
}
