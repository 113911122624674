import clsx from "clsx";
import React from "react";
import { useSearchParams } from "react-router-dom";

const CategoryCard = ({ category }) => {
  const [searchParams, setSearchParams] = useSearchParams({});

  const selectCategory = (category) => {
    const currentParams = Object.fromEntries(searchParams.entries());

    // Add the new key-value pair
    currentParams.category = category; // Replace 'groceries' with your desired value

    // Update the search params
    setSearchParams(currentParams);
  };

  return (
    <>
      <div
        className="tw-w- tw-cursor-pointer"
        onClick={() => selectCategory("iphone")}
      >
        <div
          className={clsx(
            "tw- tw-h-44", // Set the width and height of the card
            "tw-bg-cover tw-bg-center", // Ensure the background image covers the div and is centered
            "tw-rounded-lg tw-shadow-lg", // Add rounded corners and a shadow for a card-like appearance
            "tw-flex tw-items-end", // Align content to the bottom of the card
            "tw-relative"
          )}
          style={{
            backgroundImage: `url(${category?.img})`, // Replace with your image URL
          }}
        >
          <div className="tw-absolute tw-bg-black tw-opacity-50 tw-rounded tw-top-1/4 tw-right-3 p-3 tw-w-[80%]">
            <p className="tw-text-white tw-font-bold lg:tw-text-xl">
              Upload Prescription
            </p>
          </div>
        </div>
        <p className="tw-font-medium text-center tw-my-2 tw-text-[16px]">
          Prescription Medication
        </p>
      </div>
    </>
  );
};

export default CategoryCard;
