import { useEffect, useState } from "react";
import ConfirmElectricityInfo from "./ConfirmElectricityInfo";
import Electricity from "./Electricity";
import ElectricityCheckout from "./ElectricityCheckout";
import BillWarningPage from "../BillWarningPage";

export default function ElectricityBase({
  formik,
  handleClick,
  savedData,
  openModal,
}) {
  const [nextAction, setNextAction] = useState("");

  useEffect(() => {
    if (savedData) {
      setNextAction("checkout");
    }
  }, [savedData]);

  // const billData = {
  //   phone: formik?.values?.mobileNumber,
  //   amount: parseFloat(formik?.values?.amount),
  //   service_type: formik?.values?.distributionCompany?.service_type,
  //   account_number: formik?.values?.distributionCompany?.user?.accountNumber,
  //   metadata: "",
  //   email: formik?.values?.email,
  //   marketId: formik?.values?.marketPlace?.id,
  //   order_name: "electricity",
  //   account_name: formik?.values?.distributionCompany?.user?.name,
  // };
  const billData = {
    orderId: formik?.values?.distributionCompany?.verifiedData?.orderId,
    meter: formik?.values?.distributionCompany?.verifiedData?.meterNo, //"12345678910",
    disco: formik?.values?.distributionCompany?.disco,
    phone: formik?.values?.mobileNumber,
    vendType: formik?.values?.vendType,
    vertical: "ELECTRICITY",
    amount: parseFloat(formik?.values?.amount),
    email: formik?.values?.email,
    account_name: formik?.values?.distributionCompany?.verifiedData?.name,
    marketId: formik?.values?.marketPlace?.id,
    order_name: "electricity",
    payment_type: "transfer",
  };

  const payloadData = savedData ?? {
    data: {
      marketId: formik?.values?.marketPlace?.id,
      deliveryLocation: "",
      order_type: "bills",
      order_name: "electricity",
      products: [billData],
    },
  };

  //   console.log(payloadData)

  return (
    <>
      <section>
        {nextAction === "maximum_exceeded" ? (
          <BillWarningPage handleClick={handleClick} payloadData={billData} />
        ) : nextAction === "confirm_information" ? (
          <ConfirmElectricityInfo
            formik={formik}
            setNextAction={setNextAction}
            handleClick={handleClick}
            payloadData={payloadData}
          />
        ) : nextAction === "checkout" ? (
          <ElectricityCheckout
            formik={formik}
            handleClick={handleClick}
            setNextAction={setNextAction}
            payloadData={payloadData}
            openModal={openModal}
          />
        ) : (
          <Electricity
            formik={formik}
            setNextAction={setNextAction}
            handleClick={handleClick}
          />
        )}
      </section>
    </>
  );
}
