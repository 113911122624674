import { Fragment, useState } from "react";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import ModalDialogBox from "../ModalDialogBox/ModalDialogBox";
import CreatePlan from "../Admin/CreatePlan";
import EditIcon from "@mui/icons-material/Edit";
import { Modal } from "antd";

export default function ActionButton({ plan, handleClick }) {
  const [open, setOpen] = useState({ type: "", status: false });

  const handleOpen = (type) => {
    setOpen({ ...open, status: true, type });
  };

  const handleClose = () => {
    setOpen({ ...open, status: false });
  };
  return (
    <Fragment>
      <Box
        sx={{
          transform: "translateZ(0px)",
          flexGrow: 1,
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        <Box sx={{ height: 300 }}>
          <SpeedDial
            sx={{
              "& .MuiSpeedDial-fab": {
                borderRadius: "50% 0 0 50%", // Set borderRadius to 0 for square corners
              },
            }}
            ariaLabel="SpeedDial playground example"
            icon={<EditIcon />}
            direction={"down"}
            FabProps={{ size: "small", style: { backgroundColor: "#033F4B" } }}
            onClick={() => handleOpen("update_plan")}
          ></SpeedDial>
        </Box>
      </Box>

      {/* <ModalDialogBox
        open={open.status}
        handleClose={handleClose}
        // modalSize={true}
      >
        <CreatePlan
          handleClose={handleClose}
          handleClick={handleClick}
          plan={plan}
        />
      </ModalDialogBox> */}

      <Modal
        centered
        open={open?.status}
        onCancel={handleClose}
        footer={null}
        style={{
          paddingTop: "1rem",
        }}
        size
      >
        <div>
          <CreatePlan
            handleClose={handleClose}
            handleClick={handleClick}
            plan={plan}
          />
        </div>
      </Modal>
    </Fragment>
  );
}
