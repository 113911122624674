import { useGetDiscos } from "../../redux/hooks/getHooks";

export const useDiscos = () => {
  //discos
  const { data, isLoading } = useGetDiscos();

  const discos = data?.data || null;

  const networksList = ["MTN", "GLO", "AIRTEL", "9MOBILE"];
  const electicityList = [
    "ABUJA",
    "BENIN",
    "EKO",
    "ENUGU",
    "IBADAN",
    "IKEJA",
    "JOS",
    "KADUNA",
    "KAIDA",
    "KANO",
    "KOIOS",
    "YOLA",
    "PH",
    "BH",
  ];
  const cableList = ["GOTV", "DSTV", "STARTIMES"];

  const formatFn = (list) => {
    if (!discos) {
      return [];
    }
    return list?.filter((network) => network in discos);
  };

  const networkProvider = networksList; //formatFn(networksList);
  const electricProvider = formatFn(electicityList);
  const cableProvider = cableList; //formatFn(cableList);

  return { isLoading, networkProvider, electricProvider, cableProvider };
};
