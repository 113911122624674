import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState, useRef } from "react";
import * as yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Switch, Upload } from "antd";

import {
  // useCreateSubscriptionType,
  useCreateplan,
  useUpdatePlan,
} from "../../redux/mutation/AdminPostMutation";
import { UploadIcon } from "../../icons/icons";
import { useUploadImage } from "../../redux/mutation/postMutation";

const quillModules = {
  // Add any custom modules if needed
  toolbar: [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ],
};

export default function CreatePlan({ handleClose, handleClick, plan }) {
  const [isLoading, setIsLoading] = useState(null);
  const createPlanMutation = useCreateplan({});

  const uploadImageMutation = useUploadImage();

  // const createSubscriptionTypeMutation = useCreateSubscriptionType({});
  const updatePlanMutation = useUpdatePlan(plan?.id);
  const [displaySubscriptionTypeForm, setDisplaySubscriptionTypeForm] =
    useState(false);

  const quillRef = useRef(null);

  useEffect(() => {
    // Access the Quill instance
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      if (editor) {
        // Set the height of the editor
        editor.root.style.minHeight = "150px"; // Adjust the height as needed
      }
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      image: "",
      name: plan?.name ?? "",
      amount: plan?.amount ?? null,
      repayment_duration: plan?.repayment_duration ?? null,
      plan_type: plan?.plan_type ?? "",
      credit_times_limit: plan?.credit_times_limit ?? null,
      credit_limit: plan?.credit_limit ?? null,
      description: plan?.description ?? "",
      planId: plan?.planId ?? "",
      type: "",
      is_free: false,
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().required("Plan name is required"),

      // amount: yup.number().when('is_free', {
      //   is: false,
      //   then: yup.number().required("Plan amount is required"),
      //   otherwise: (schema) => schema
      // }),
      repayment_duration: yup
        .number()
        .required("Plan repayment duration is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const {
        name,
        amount,
        repayment_duration,
        description,
        credit_times_limit,
        grade,
        plan_type,
        credit_limit,
        image,
        is_free,
      } = values;

      if (image instanceof File) {
        // IF it's true, it means there's image url already and it is not changed

        const formData = new FormData();
        formData.append("image", image);

        setIsLoading(true);
        await uploadImageMutation.mutate(formData, {
          onSuccess: (data) => {
            handleMutation(
              {
                image: data?.data?.data,
                name,
                amount: is_free ? 0 : amount,
                repayment_duration,
                description,
                plan_type,
                credit_times_limit,
                credit_limit,
                is_free,
              },
              resetForm
            );
          },
        });
      } else {
        handleMutation(
          {
            image,
            name,
            amount: is_free ? 0 : amount,
            repayment_duration,
            description,
            plan_type,
            credit_times_limit,
            credit_limit,
            is_free,
          },
          resetForm
        );
      }
    },
  });

  //mutation function
  const handleMutation = (payload, resetForm) => {
    plan
      ? updatePlanMutation.mutate(payload, {
          onSuccess: () => {
            handleClose();
            resetForm();
          },
        })
      : createPlanMutation.mutate(payload, {
          onSuccess: () => {
            // setDisplaySubscriptionTypeForm(true);
            resetForm();
          },
        });
  };

  //useEffect for image upload
  useEffect(() => {
    if (uploadImageMutation.isLoading) {
      setIsLoading(true);
    } else if (uploadImageMutation.isSuccess) {
      setIsLoading(false);

      let msg = uploadImageMutation?.data?.data?.message;
      formik.setFieldValue("image", uploadImageMutation?.data?.data?.data);

      handleClick(msg, "success");
    } else if (uploadImageMutation.isError) {
      setIsLoading(false);
      let msg1 = uploadImageMutation?.error?.response?.data?.message;
      let msg2 = uploadImageMutation?.error?.message;
      handleClick(msg1 ?? msg2, "error");
    }
  }, [uploadImageMutation.status]);

  // mutation to create and update plan
  useEffect(() => {
    if (createPlanMutation?.isLoading || updatePlanMutation?.isLoading) {
      setIsLoading(true);
    } else if (createPlanMutation?.isError || updatePlanMutation?.isError) {
      setIsLoading(false);
      let message = plan
        ? updatePlanMutation?.error?.response?.data?.message
        : createPlanMutation?.error?.response?.data?.message;
      let message2 = plan
        ? updatePlanMutation?.error?.message
        : createPlanMutation?.error?.message;

      handleClick(message ? message : message2, "error");
    } else if (createPlanMutation?.isSuccess || updatePlanMutation?.isSuccess) {
      setIsLoading(false);
      const res = plan
        ? updatePlanMutation?.data?.data
        : createPlanMutation?.data?.data;
      formik.setFieldValue("planId", res?.data?.id);
      handleClose();
    }
  }, [createPlanMutation?.status, updatePlanMutation?.status]);

  const handleQuillChange = (desc) => {
    formik.setFieldValue("description", desc);
  };

  const props = {
    onRemove: (file) => {
      // const index = formik.values.image.indexOf(file);
      // const newFileList = fileList.slice();
      // newFileList.splice(index, 1);
      // setFileList(newFileList);
    },
    beforeUpload: (file) => {
      formik.setFieldValue("image", file);
      return false;
    },
    multiple: false,
  };

  const onChange = (checked) => {
    formik.setFieldValue("is_free", checked);
  };

  function isLink(value) {
    // Regular expression to match URLs
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    // Test if the value matches the URL pattern
    return urlRegex.test(value);
  }

  return (
    <>
      <main>
        <form className="" onSubmit={formik.handleSubmit}>
          {
            // displaySubscriptionTypeForm ? (
            //   <>
            //   <h3 className="text-center">Create Subscription Type</h3>
            //   <div className={formik?.values?.planId ? "d-block" : "none"}>
            //     <div className="col-12">
            //       <div className="label my-2">
            //         <label htmlFor="plan_name">Type</label>
            //       </div>

            //       <Select
            //         className="rounded-pill"
            //         fullWidth
            //         size="small"
            //         name="type"
            //         onChange={formik.handleChange}
            //         onBlur={formik.handleBlur}
            //         value={formik?.values?.type}
            //         placeholder="Subscription Type"
            //       >
            //         <MenuItem value="">Subscription Type</MenuItem>
            //         <MenuItem value="monthly">Monthly</MenuItem>
            //         <MenuItem value="quarterly">Quarterly</MenuItem>
            //         <MenuItem value="semi-annually">Semi-Annually</MenuItem>
            //         <MenuItem value="annually">Annually</MenuItem>
            //       </Select>
            //       <FormHelperText error>
            //         {formik.touched.type && formik.errors.type}
            //       </FormHelperText>
            //     </div>
            //     <div className="col-12">
            //       <div className="label my-2">
            //         <label htmlFor="planPrice">Plan Price</label>
            //       </div>
            //       <TextField
            //         type="number"
            //         name="amount"
            //         required
            //         error={formik.touched.amount && formik.errors.amount}
            //         onChange={formik.handleChange}
            //         onBlur={formik.handleBlur}
            //         value={formik.values.amount}
            //         placeholder="Enter the plan price"
            //         fullWidth
            //         variant="standard"
            //         size="small"
            //       />
            //       <small style={{ fontSize: "13px" }} className="text-danger">
            //         {formik.touched.amount && formik.errors.amount}
            //       </small>
            //     </div>
            //   </div>
            //   </>
            // ) :
            // plan ? (
            //   <div className="col-12" style={{paddingBottom: "20px"}}>
            //     <div className="label" style={{marginBottom: "4px"}} >
            //       <label htmlFor="planPrice">Plan Price</label>
            //     </div>
            //     <TextField
            //       type="number"
            //       name="amount"
            //       required
            //       error={formik.touched.amount && formik.errors.amount}
            //       onChange={formik.handleChange}
            //       onBlur={formik.handleBlur}
            //       value={formik.values.amount}
            //       placeholder="Enter the plan price"
            //       fullWidth
            //       variant="standard"
            //       size="small"
            //     />
            //     <small style={{ fontSize: "13px" }} className="text-danger">
            //       {formik.touched.amount && formik.errors.amount}
            //     </small>
            //   </div>
            // ) :
            <>
              <h3 className="text-center">
                {plan ? "Edit Plan" : "Create Plan"}
              </h3>
              <div className="col-12" style={{ paddingBottom: "16px" }}>
                <div className="label" style={{ marginBottom: "4px" }}>
                  <label htmlFor="">Plan Image</label>
                </div>
                <Upload
                  {...props}
                  fileList={[formik.values.image ?? null]}
                  accept=".jpg, .jpeg, .png"
                >
                  <Button icon={<UploadIcon />}>Select File</Button>
                </Upload>
                <small style={{ fontSize: "13px" }} className="text-danger">
                  {formik.touched.image && formik.errors.image}
                </small>
              </div>
              <div className="col-12" style={{ paddingBottom: "16px" }}>
                <div className="label" style={{ marginBottom: "4px" }}>
                  <label htmlFor="plan_name">Name</label>
                </div>
                <TextField
                  type="text"
                  name="name"
                  required
                  error={formik.touched.name && formik.errors.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  placeholder="Enter the plan name"
                  fullWidth
                  variant="standard"
                  size="small"
                />
                <small style={{ fontSize: "13px" }} className="text-danger">
                  {formik.touched.name && formik.errors.name}
                </small>
              </div>
              {!formik.values.is_free && (
                <div className="col-12" style={{ paddingBottom: "16px" }}>
                  <div className="label my-2" style={{ marginBottom: "4px" }}>
                    <label htmlFor="planPrice">Price</label>
                  </div>
                  <TextField
                    type="number"
                    name="amount"
                    required
                    error={
                      formik.values.is_free
                        ? null
                        : formik.touched.amount && formik.errors.amount
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.amount}
                    placeholder="Enter the plan price"
                    fullWidth
                    variant="standard"
                    size="small"
                  />
                  <small style={{ fontSize: "13px" }} className="text-danger">
                    {formik.touched.amount && formik.errors.amount}
                  </small>
                </div>
              )}
              <div className="col-12" style={{ paddingBottom: "16px" }}>
                <div className="label my-2" style={{ marginBottom: "4px" }}>
                  <label htmlFor="plan_duration">Repayment Duration</label>
                </div>
                <TextField
                  type="number"
                  name="repayment_duration"
                  required
                  error={
                    formik.touched.repayment_duration &&
                    formik.errors.repayment_duration
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.repayment_duration}
                  placeholder="Enter the repayment duration"
                  fullWidth
                  variant="standard"
                  size="small"
                />
                <small style={{ fontSize: "13px" }} className="text-danger">
                  {formik.touched.repayment_duration &&
                    formik.errors.repayment_duration}
                </small>
              </div>
              <div className="col-12" style={{ paddingBottom: "16px" }}>
                <div className="label my-2" style={{ marginBottom: "4px" }}>
                  <label htmlFor="plan_type">Plan Type</label>
                </div>
                <TextField
                  type="text"
                  name="plan_type"
                  // required
                  error={formik.touched.plan_type && formik.errors.plan_type}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.plan_type}
                  placeholder="Enter plan type"
                  fullWidth
                  variant="standard"
                  size="small"
                />
                <small style={{ fontSize: "13px" }} className="text-danger">
                  {formik.touched.plan_type && formik.errors.plan_type}
                </small>
              </div>
              <div className="col-12" style={{ paddingBottom: "16px" }}>
                <div className="label my-2" style={{ marginBottom: "4px" }}>
                  <label htmlFor="plan_type">Credit time Limit</label>
                </div>
                <TextField
                  type="number"
                  name="credit_times_limit"
                  required
                  error={
                    formik.touched.credit_times_limit &&
                    formik.errors.credit_times_limit
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.credit_times_limit}
                  placeholder="Credit times limit"
                  fullWidth
                  variant="standard"
                  size="small"
                />
                <small style={{ fontSize: "13px" }} className="text-danger">
                  {formik.touched.credit_times_limit &&
                    formik.errors.credit_times_limit}
                </small>
              </div>
              <div className="col-12" style={{ paddingBottom: "16px" }}>
                <div className="label my-2" style={{ marginBottom: "4px" }}>
                  <label htmlFor="credit_limit">Credit Limit</label>
                </div>
                <TextField
                  type="number"
                  name="credit_limit"
                  required
                  error={
                    formik.touched.credit_limit && formik.errors.credit_limit
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.credit_limit}
                  placeholder="Plan credit limit"
                  fullWidth
                  variant="standard"
                  size="small"
                />
                <small style={{ fontSize: "13px" }} className="text-danger">
                  {formik.touched.credit_limit && formik.errors.credit_limit}
                </small>
              </div>
              <div className="col-12" style={{ paddingBottom: "20px" }}>
                <div className="label" style={{ marginBottom: "4px" }}>
                  <label htmlFor="" className="mx-3">
                    Plan status
                  </label>
                  <Switch
                    defaultChecked={false}
                    checked={formik?.values?.status}
                    onChange={onChange}
                    checkedChildren="Free"
                    unCheckedChildren="Not Free"
                  />
                </div>
              </div>

              <div className="col-12" style={{ paddingBottom: "16px" }}>
                <div className="label my-2" style={{ marginBottom: "4px" }}>
                  <label htmlFor="plan_duration">Description</label>
                </div>

                <ReactQuill
                  ref={quillRef}
                  theme="snow"
                  value={formik.values.description}
                  onChange={handleQuillChange}
                  placeholder="Write Something Here..."
                  // className={`d-flex flex-grow-1 border-0 rounded w-100`}
                  modules={quillModules}
                />
                <small style={{ fontSize: "13px" }} className="text-danger">
                  {formik.touched.description && formik.errors.description}
                </small>
              </div>
            </>
          }

          <div
            className="d-flex justify-content-flex-end"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {/* <button type="submit" className="my-3 btn btn-primary">Submit</button> */}
            <Box sx={{ m: 1, position: "relative" }}>
              <Button
                disabled={isLoading}
                type="submit"
                style={isLoading ? { cursor: "not-allowed" } : {}}
                variant="contained"
                sx={
                  isLoading
                    ? { cursor: "not-allowed", backgroundColor: "#033F4B" }
                    : {
                        backgroundColor: "#033F4B",
                      }
                }
              >
                {plan ? "Update" : "Create"}
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "25%",
                    left: "40%",
                  }}
                />
              )}
            </Box>
          </div>
        </form>
      </main>
    </>
  );
}
