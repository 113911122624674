import clsx from "clsx";
import React from "react";
import { useUserData } from "../../hooks/useUserData";
import { useDispatch } from "react-redux";
import { productAction } from "../../../redux/slice/productSlice";

const ProductItem = ({ item }) => {
  const { data } = useUserData();

  const dispatch = useDispatch();

  const sessionKey = data?.id + "_product";
  let products = JSON.parse(sessionStorage.getItem(sessionKey));

  const updatePrice = (products) => {
    dispatch(productAction.setTotalPrice());
  };

  const addToCart = (item) => {
    const {
      category,
      marketPlace,
      product,
      productUrl,
      description,
      price,
      deliveryLocation,
    } = item;
    //   setIsLoading(true);

    const nPrice = price?.replace(/,/g, "");

    const data = {
      marketPlace: marketPlace,
      products: [
        {
          category: category,
          name: product,
          url: productUrl,
          description: description,
          price: parseFloat(nPrice),
          quantity: 1,
        },
      ],
      deliveryLocation: deliveryLocation,
      repaymentDuration: 0,
    };

    if (products) {
      products?.products?.push(data.products[0]);
      // console.log(products);
      dispatch(productAction.setAllProducts(products));
      updatePrice(products);
      sessionStorage.setItem(sessionKey, JSON.stringify(products));
    } else {
      dispatch(productAction.setAllProducts(data));
      updatePrice(data);
      sessionStorage.setItem(sessionKey, JSON.stringify(data));
    }
    // location === ROUTES.cart ? handleClose() : navigate(ROUTES.cart);
    // setIsLoading(false);
  };

  return (
    <>
      <div className="tw-group tw-relative tw-block tw-overflow-hidden tw-rounded">
        <button className="tw-absolute tw-end-4 tw-top-4 tw-z-10 tw-rounded-full tw-bg-white tw-p-1.5 tw-text-gray-900 tw-transition hover:tw-text-gray-900/75">
          <span className="tw-sr-only">Wishlist</span>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="tw-size-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
            />
          </svg>
        </button>
        <img
          src="https://images.unsplash.com/photo-1599481238640-4c1288750d7a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2664&q=80"
          alt=""
          className="tw-h-64 tw-w-full tw-object-cover tw-transition tw-duration-500 group-hover:tw-scale-105 sm:tw-h-56"
        />

        <div className="tw-relative tw-border tw-border-gray-100 tw-bg-white tw-px-6 tw-pb-6">
          {/* <span className="tw-bg-yellow-400 tw-px-3 tw-py-1.5 tw-text-xs tw-font-medium tw-whitespace-nowrap">
                        {" "}
                        New{" "}
                    </span> */}

          <h3 className="tw-mt-4 tw-text-lg tw-font-medium tw-text-gray-900">
            Baby Diaper
          </h3>
          <p className={clsx("tw-opacity-75 tw-leading-none tw-mt-1.5")}>
            Description
          </p>

          <p className="tw-mt-1.5 tw-text-sm tw-text-gray-700">N 14.99</p>

          <div className="tw-mt-4">
            <button
              className="tw-block tw-w-full tw-bg-[#033F4B] tw-text-white tw-p-4 tw-text-sm tw-font-medium tw-transition hover:tw-scale-105 tw-rounded-full"
              onClick={addToCart}
            >
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductItem;
