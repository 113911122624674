import { Button } from "antd";
import React, { useState, useEffect } from "react";
import AuthHeader from "../../../components/Auth/AuthHeader";
import { MenuItem, Select, TextField } from "@mui/material";
import mtn from "../../../assets/mtn.png";
import airtel from "../../../assets/airtel.png";
import smile from "../../../assets/smile.png";
import glo from "../../../assets/glo.png";
import mobile9 from "../../../assets/9mobile.png";
import {
  useGetAirtimeProvider,
  useGetDataBundle,
} from "../../../redux/hooks/getHooks";
import { formatNumber } from "../../../utils/formatNumber";
import { useUserData } from "../../../components/hooks/useUserData";
import { useDiscos } from "../../../components/hooks/useDiscos";

export default function Form({ formik, setNextAction }) {
  const [selectedProvider, setSelectedProvider] = useState(0);

  const [selectedOption, setSelectedOption] = useState(null);

  const [activateNextBtn, setActivateNextBtn] = useState(false);

  // const networkProvider = useGetAirtimeProvider({});
  const { networkProvider } = useDiscos({});

  const user = useUserData();

  const getBundles = useGetDataBundle({
    // service_type: formik?.values?.service?.service_type || "mtn",
    vertical: "DATA",
    provider: formik?.values?.service || "MTN",
  });

  const bundles = getBundles?.data?.data?.data;
  // const providers = networkProvider?.data?.data?.data?.providers;
  const providers = networkProvider;

  const handleChooseService = (service) => {
    formik.setFieldValue("service", service);
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value; // Get the value of the selected option
    // console.log(selectedValue)
    const selectedObject = bundles.find(
      (option) => option.code === selectedValue
    );

    formik.setFieldValue("dataBundle", selectedObject);
    setSelectedOption(selectedObject);
  };

  const handleNextAction = () => {
    setNextAction("confirm_information");
  };

  useEffect(() => {
    if (
      formik?.values?.mobileNumber &&
      formik?.values?.service &&
      formik?.values?.dataBundle
    ) {
      setActivateNextBtn(true);
    } else {
      setActivateNextBtn(false);
    }
  }, [formik]);

  const onChangeNumber = (e) => {
    formik.setFieldValue("mobileNumber", e.target.value);
  };

  return (
    <>
      <form className="col-lg-8 col-12 mx-aut" onSubmit={formik?.handleSubmit}>
        <AuthHeader title={"Buy Mobile Data"} />

        <div
          className="mobileNumberNumber col-12"
          style={{ paddingBottom: "20px" }}
        >
          <div className="label" style={{ marginBottom: "4px" }}>
            <label htmlFor="mobileNumberNumber">Mobile Number</label>
          </div>
          <TextField
            type="number"
            name="mobileNumber"
            // required
            error={formik?.touched.mobileNumber && formik?.errors.mobileNumber}
            onChange={onChangeNumber}
            onBlur={formik?.handleBlur}
            value={formik?.values.mobileNumber}
            placeholder="Provide mobileNumber number e.g 08012345678"
            fullWidth
            variant="outlined"
            size="medium"
            InputProps={{
              style: {
                borderRadius: "30px",
                fontSize: "14px",
              },
            }}
          />
          <small style={{ fontSize: "13px" }} className="text-danger">
            {formik?.touched.mobileNumber && formik?.errors.mobileNumber}
          </small>
        </div>

        <div className="" style={{ paddingBottom: "20px" }}>
          <div className="label" style={{ marginBottom: "4px" }}>
            <label htmlFor="">Choose Network</label>
          </div>
          <div className="d-flex gap-3 flex-wrap">
            {networkProvider?.isLoading ? (
              <i>Loading</i>
            ) : (
              providers
                ?.filter(
                  (item) => item?.toLowerCase() !== "internatinal-airtime"
                )
                ?.map((item, index) => (
                  <div key={index}>
                    <img
                      src={
                        item?.toLowerCase() === "mtn"
                          ? mtn
                          : item?.toLowerCase()?.includes("airtel")
                          ? airtel
                          : item?.toLowerCase()?.includes("glo")
                          ? glo
                          : item?.toLowerCase()?.includes("smile")
                          ? smile
                          : item?.toLowerCase()?.includes("9mobile") && mobile9
                      }
                      alt={"_logo"}
                      onClick={() => {
                        handleChooseService(item);
                      }}
                      className={`p-1 cursor-pointer ${
                        formik?.values?.service === item &&
                        "border-3 border-success"
                      }`}
                      style={{
                        width: "57px",
                        maxWidth: "57px",
                        height: "100%",
                        borderRadius: "10px",
                        border: "1px solid #DBDBDB9C",
                        transition: "0.3s ease-in-out",
                      }}
                    />
                    {/* <p className="text-center">{item?.service_type}</p> */}
                  </div>
                ))
            )}
          </div>
        </div>

        <div className="bundle" style={{ paddingBottom: "20px" }}>
          <div className="label" style={{ marginBottom: "4px" }}>
            <label htmlFor="bundle">Select data bundle</label>
          </div>
          <Select
            name="dataBundle"
            placeholder="Data bundle"
            value={formik?.values.dataBundle?.code}
            error={formik?.touched.dataBundle && formik?.errors.dataBundle}
            onChange={handleSelectChange}
            onBlur={formik?.handleBlur}
            fullWidth
            variant="outlined"
            sx={{ borderRadius: "30px" }}
            size="medium"
          >
            {bundles?.map((bundle, index) => {
              return (
                <MenuItem key={index + bundle?.desc} value={bundle?.code}>
                  {bundle?.desc} - ({formatNumber(bundle?.price)})
                </MenuItem>
              );
            })}
          </Select>

          <small style={{ fontSize: "13px" }} className="text-danger">
            {formik?.touched.dataBundle && formik?.errors.dataBundle}
          </small>
        </div>

        {user?.status ? (
          ""
        ) : (
          <>
            <div className="Email col-12" style={{ paddingBottom: "20px" }}>
              <div className="label" style={{ marginBottom: "4px" }}>
                <label htmlFor="Email">Email</label>
              </div>
              <TextField
                type="email"
                name="email"
                required
                error={formik?.touched.email && formik?.errors.email}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                placeholder="Provide email"
                fullWidth
                value={formik?.values.email}
                variant="outlined"
                size="medium"
                InputProps={{
                  style: {
                    borderRadius: "30px",
                    fontSize: "14px",
                  },
                }}
              />
              <small style={{ fontSize: "13px" }} className="text-danger">
                {formik?.touched.email && formik?.errors.email}
              </small>
            </div>
            <div className="col-12" style={{ paddingBottom: "20px" }}>
              <div className="label" style={{ marginBottom: "4px" }}>
                <label htmlFor="">Name</label>
              </div>
              <TextField
                type="text"
                name="name"
                required
                error={formik?.touched.name && formik?.errors.name}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                placeholder="Enter your Name"
                fullWidth
                value={formik?.values.name}
                variant="outlined"
                size="medium"
                InputProps={{
                  style: {
                    borderRadius: "30px",
                    fontSize: "14px",
                  },
                }}
              />
              <small style={{ fontSize: "13px" }} className="text-danger">
                {formik?.touched.name && formik?.errors.name}
              </small>
            </div>
          </>
        )}

        <div className="submit_btn pb-2">
          <Button
            size="large"
            shape="round"
            loading={false}
            disabled={!activateNextBtn}
            style={{
              backgroundColor: activateNextBtn ? "#033F4B" : "#f0f0f0", // Change background color based on activation state
              height: "55px",
              color: activateNextBtn ? "white" : "#ccc", // Change text color based on activation state
              width: "100%",
              border: "none", // Remove border
            }}
            onClick={handleNextAction}
          >
            Next
          </Button>
        </div>
      </form>
    </>
  );
}
