import { Button } from "antd";
import React from "react";
import { motion } from "framer-motion";
import clsx from "clsx";
import { useSearchParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const StoreLayout = ({ onBack, children }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const shopName = searchParams.get("name");

  const storeList = [`${shopName} 1`, `${shopName} 2`, `${shopName} 3`];
  return (
    <>
      <main className="tw-container tw-flex tw-gap-3 tw-relative">
        <div className="tw-h-screen tw-pt-10">
          <div className="px-3">
            <Button shape="circle" icon={<FaArrowLeft />} onClick={onBack} />
          </div>
          <motion.div
            className="border-end tw-flex tw-flex-col tw-w-[17rem] px-3 tw-z-10 tw-h-screen tw-pt-10"
            initial={{ x: -300 }}
            animate={{ x: 0 }}
            exit={{ x: -300 }}
            transition={{ duration: 0.5 }}
          >
            <div className="d-flex flex-column gap-3">
              {storeList?.map((store) => (
                <div
                  className={clsx(
                    "tw-inline-flex tw-rounded-full tw-border tw-py-5 tw-px-5 tw-text-center tw-cursor-pointer tw-transition-all tw-duration-300 tw-bg-white"
                    //   activeStore === index
                    //     ? "tw-border-black"
                    //     : "tw-border-default-400"
                  )}
                  // onClick={() => selectStore(store, index)}
                >
                  {store}
                </div>
              ))}
            </div>
          </motion.div>
        </div>
        <div className="tw-pt-10 tw-w-full">{children}</div>
      </main>
    </>
  );
};

export default StoreLayout;
