import clsx from "clsx";
import Title from "../../../../components/core/shared/Title";
import { useState } from "react";
import StoreLayout from "@/layouts/shopper_layout/StoreLayout";
import OnlineMarket from "./OnlineMarket";
import { useNavigate, useSearchParams } from "react-router-dom";
import ProductsView from "../../../../components/core/online_market/ProductsView";

const StoreView = () => {
  const [activeStore, setActiveStore] = useState(0);
  const navigate = useNavigate({});

  const [searchParams, setSearchParams] = useSearchParams({});

  const category = searchParams.get("category");

  const stores = [
    { name: "Supermarkets" },
    { name: "Online Markets" },
    { name: "Bill" },
    { name: "Pharmacy" },
    { name: "Food stuff" },
  ];

  const selectStore = (store, index) => {
    setActiveStore(index);
  };

  const onBack = () => {
    navigate(-1);
  };

  return (
    <>
      {category ? (
        <>
          <ProductsView />
        </>
      ) : (
        <StoreLayout onBack={onBack}>
          <OnlineMarket />
        </StoreLayout>
      )}
    </>
  );
};

export default StoreView;
