
import PropTypes from 'prop-types';
import clsx from "clsx";

const Title = ({ title, sub, className, position, classNames = {}, onBack, isDisabled }) => {
  return (
    <div className={clsx('tw-flex tw-items-center tw-space-x-4', className, classNames.base || '')}>
      <div className={clsx(position==="center" ? 'tw-text-center': position==="left"? "tw-text-left": position==="right" && "tw-text-right")}>
        <h2 className={clsx('tw-text-2xl tw-font-medium tw-leading-none', classNames.title || '')}>{title}</h2>
        {!!sub && <p className={clsx('tw-opacity-75 tw-leading-none tw-mt-1.5', classNames.sub || '')}>{sub}</p>}
      </div>
    </div>
  );
};

Title.propTypes = {
  title: PropTypes.string,
  sub: PropTypes.string,
  className: PropTypes.string,
  classNames: PropTypes.shape({
    base: PropTypes.string,
    title: PropTypes.string,
    sub: PropTypes.string,
  }),
  onBack: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default Title;