import { Button, Select } from "antd";
import React from "react";
import Banner from "./Banner";
import clsx from "clsx";
import ProductItem from "./ProductItem";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { Input } from "antd";
const { Search } = Input;

const ProductsView = () => {
  const navigate = useNavigate({});

  const onBack = () => {
    navigate(-1);
  };

  return (
    <>
      <main>
        {/* <Banner /> */}

        <div className="tw-my-8">
          <div>
            <Button shape="circle" icon={<FaArrowLeft />} onClick={onBack} />
          </div>
          <div className="tw-flex tw-justify-between my-3">
            <div className="tw-flex tw-gap-3">
              <h3 className="tw-text-xl tw-text-black tw-whitespace-nowrap">
                Product List
              </h3>
              <Input
                className="tw-rounded-full"
                placeholder="Search item"
                size="large"
                style={{ width: 300 }}
              />
            </div>
            <div className="">
              <Select
                placeholder={"Select Category"}
                className="tw-min-w-32 tw-rounded-full"
                //   onChange={handleChange}
                options={[
                  {
                    value: "jack",
                    label: "Jack",
                  },
                  {
                    value: "lucy",
                    label: "Lucy",
                  },
                  {
                    value: "Yiminghe",
                    label: "yiminghe",
                  },
                  {
                    value: "disabled",
                    label: "Disabled",
                    disabled: true,
                  },
                ]}
              />
            </div>
          </div>

          <div>
            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4 gap-3">
              {[1, 2, 3, 4, 5]?.map((item, index) => (
                <ProductItem />
              ))}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ProductsView;
