import { Button } from "antd";
import React, { useEffect, useState } from "react";
import AuthHeader from "../../../components/Auth/AuthHeader";
import { TextField } from "@mui/material";
import mtn from "../../../assets/mtn.png";
import airtel from "../../../assets/airtel.png";
import smile from "../../../assets/smile.png";
import glo from "../../../assets/glo.png";
import mobile9 from "../../../assets/9mobile.png";
import { useUserData } from "../../../components/hooks/useUserData";
import { useGetAirtimeProvider } from "../../../redux/hooks/getHooks";
import { useDiscos } from "../../../components/hooks/useDiscos";

export default function Form({ formik, setNextAction }) {
  const user = useUserData();

  const [selectedProvider, setSelectedProvider] = useState(0);
  const [activateNextBtn, setActivateNextBtn] = useState(false);

  // const airtimeProviders = useGetAirtimeProvider({});

  // console.log(airtimeProviders?.data?.data);

  const airtimeProviders = useDiscos();

  // const providers = airtimeProviders?.data?.data?.data?.providers;
  const providers = airtimeProviders?.networkProvider;

  useEffect(() => {
    //select the first network by default
    if (providers?.length) {
      formik.setFieldValue("service", providers[0]);
    }
  }, []);

  const handleChooseService = (service) => {
    formik.setFieldValue("service", service);
  };

  const handleNextAction = () => {
    setNextAction("confirm_information");
  };

  useEffect(() => {
    if (
      formik?.values?.mobileNumber &&
      formik?.values?.service &&
      formik?.values?.amount
    ) {
      setActivateNextBtn(true);
    } else {
      setActivateNextBtn(false);
    }
  }, [formik]);

  const onChangeNumber = (e) => {
    formik.setFieldValue("mobileNumber", e.target.value);
  };

  useEffect(() => {
    if (Number(formik.values.amount) < 100) {
      formik.setFieldError("amount", "Minimum amount is N100");
    }
  }, [formik]);

  const hasErrors = Object.keys(formik?.errors).length > 0;

  return (
    <>
      <form className="col-lg-8 col-12 mx-aut" onSubmit={formik?.handleSubmit}>
        <AuthHeader title={"Buy Airtime"} />

        <div
          className="mobileNumberNumber col-12"
          style={{ paddingBottom: "20px" }}
        >
          <div className="label" style={{ marginBottom: "4px" }}>
            <label htmlFor="mobileNumber">Mobile Number</label>
          </div>
          <TextField
            type="number"
            name="mobileNumber"
            // required
            error={formik?.touched.mobileNumber && formik?.errors.mobileNumber}
            onChange={onChangeNumber}
            onBlur={formik?.handleBlur}
            value={formik?.values.mobileNumber}
            placeholder="Provide mobile Number e.g 08012345678"
            fullWidth
            variant="outlined"
            size="medium"
            InputProps={{
              style: {
                borderRadius: "30px",
                fontSize: "14px",
              },
            }}
          />
          <small style={{ fontSize: "13px" }} className="text-danger">
            {formik?.touched.mobileNumber && formik?.errors.mobileNumber}
          </small>
        </div>

        <div className="" style={{ paddingBottom: "20px" }}>
          <div className="label" style={{ marginBottom: "4px" }}>
            <label htmlFor="">Choose Network</label>
          </div>
          <div className="d-flex gap-3 flex-wrap">
            {airtimeProviders?.isLoading ? (
              <i>Loading</i>
            ) : (
              providers
                ?.filter(
                  (item) => item?.toLowerCase() !== "internatinal-airtime"
                )
                ?.map((item, index) => (
                  <div key={index}>
                    <img
                      src={
                        item?.toLowerCase() === "mtn"
                          ? mtn
                          : item?.toLowerCase()?.includes("airtel")
                          ? airtel
                          : item?.toLowerCase()?.includes("glo")
                          ? glo
                          : item?.toLowerCase()?.includes("smile")
                          ? smile
                          : item?.toLowerCase()?.includes("9mobile") && mobile9
                      }
                      alt={"_logo"}
                      onClick={() => {
                        handleChooseService(item);
                      }}
                      className={`p-1 cursor-pointer ${
                        formik?.values?.service === item &&
                        "border-3 border-success"
                      }`}
                      style={{
                        width: "57px",
                        maxWidth: "57px",
                        height: "100%",
                        borderRadius: "10px",
                        border: "1px solid #DBDBDB9C",
                        transition: "0.3s ease-in-out",
                      }}
                    />
                    {/* <p className="text-center">{item?.service_type}</p> */}
                  </div>
                ))
            )}
          </div>
        </div>

        <div className="amount" style={{ paddingBottom: "20px" }}>
          <div className="label" style={{ marginBottom: "4px" }}>
            <label htmlFor="amount">Amount</label>
          </div>
          <TextField
            type="number"
            id="standard-basic"
            name="amount"
            required
            error={formik?.touched.amount && formik?.errors.amount}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            placeholder="Enter amount e.g 5,000"
            fullWidth
            className="amount"
            value={formik?.values.amount}
            variant="outlined"
            size="large"
            min={"60"}
            InputProps={{
              style: {
                borderRadius: "30px",
                fontSize: "14px",
              },
            }}
          />
          <small style={{ fontSize: "13px" }} className="text-danger">
            {formik?.touched.amount && formik?.errors.amount}
          </small>
        </div>

        {user?.status ? (
          ""
        ) : (
          <>
            <div className="Email col-12" style={{ paddingBottom: "20px" }}>
              <div className="label" style={{ marginBottom: "4px" }}>
                <label htmlFor="Email">Email</label>
              </div>
              <TextField
                type="email"
                name="email"
                required
                error={formik?.touched.email && formik?.errors.email}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                placeholder="Provide email"
                fullWidth
                value={formik?.values.email}
                variant="outlined"
                size="medium"
                InputProps={{
                  style: {
                    borderRadius: "30px",
                    fontSize: "14px",
                  },
                }}
              />
              <small style={{ fontSize: "13px" }} className="text-danger">
                {formik?.touched.email && formik?.errors.email}
              </small>
            </div>
            <div className="col-12" style={{ paddingBottom: "20px" }}>
              <div className="label" style={{ marginBottom: "4px" }}>
                <label htmlFor="">Name</label>
              </div>
              <TextField
                type="text"
                name="name"
                required
                error={formik?.touched.name && formik?.errors.name}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                placeholder="Enter your Name"
                fullWidth
                value={formik?.values.name}
                variant="outlined"
                size="medium"
                InputProps={{
                  style: {
                    borderRadius: "30px",
                    fontSize: "14px",
                  },
                }}
              />
              <small style={{ fontSize: "13px" }} className="text-danger">
                {formik?.touched.name && formik?.errors.name}
              </small>
            </div>
          </>
        )}

        <div className="submit_btn pb-2 mt-3">
          <Button
            size="large"
            shape="round"
            loading={false}
            disabled={!activateNextBtn || hasErrors}
            style={{
              backgroundColor:
                !activateNextBtn || hasErrors ? "#f0f0f0" : "#033F4B", // Change background color based on activation state
              color: activateNextBtn ? "white" : "#ccc", // Change text color based on activation state
              width: "100%",
              height: "55px",
              border: "none", // Remove border
            }}
            onClick={handleNextAction}
          >
            Next
          </Button>
        </div>
      </form>
    </>
  );
}
